<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>居家管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <span class="demonstration">系统名称：</span>
          <el-select
            v-model="queryMenuFrom.productSource"
            placeholder="请选择系统"
            clearable=""
          >
            <el-option
              v-for="item in systemList"
              :key="item.uniqueCode"
              :label="item.name"
              :value="item.uniqueCode"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <span class="demonstration">菜单类型:</span>
          <el-select
            v-model="queryMenuFrom.type"
            placeholder="请选择系统"
            clearable
          >
            <el-option
              v-for="item in menusTypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="primay" @click="menuListButton()">查询</el-button>
          <el-button type="primay">重置</el-button>
        </el-col>
      </el-row>
      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin: 9px 2px">
        <el-button
          type="warning"
          icon="el-icon-add"
          size="min"
          @click="addMenuButton"
          >新增</el-button
        >
      </el-row>
      <!-- 列表区域 -->
      <el-table
        :data="menuDataList"
        border
        row-key="code"
        stripe
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          label="选择"
          width="70"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <el-radio
              class="radio"
              v-model="questionnaireId"
              :label="scope.row.id"
              @click.native.stop.prevent="getCurrentRow(scope.row)"
              >&nbsp;</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column label="归属系统" prop="productSourceName" />
        <el-table-column label="菜单类型" prop="type" :formatter="mensTypeMenus"/>
        <el-table-column label="菜单名称" prop="name" />
        <!-- <el-table-column label="菜单图标" prop="icon" /> -->
        <el-table-column label="菜单编码" prop="code" />
        <el-table-column label="菜单路径" prop="path" />
        <el-table-column label="组件路径" prop="component" />
        <el-table-column label="状态" prop="status" />
        <el-table-column label="高级状态" prop="seniorStatus" />
        <el-table-column label="是否公共" prop="isCommon" />
        <el-table-column label="排序" prop="sort" />
        <el-table-column label="相关资源" prop="bindButtonListSize">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.bindButtonListSize != null &&
                scope.row.bindButtonListSize != 0
              "
              style="color: #108ee9; cursor: pointer"
              @click="showButtonListButton(scope.row)"
              >{{ scope.row.bindButtonListSize }}</el-button
            >
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editMenu(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button size="mini" type="text" icon="el-icon-edit" disabled
              >详情</el-button
            >
            <el-button size="mini" type="text" icon="el-icon-edit" disabled
              >删除</el-button
            >
            <el-button size="mini" type="text" icon="el-icon-edit" disabled
              >添加下级</el-button
            >
            <el-button size="mini" type="text" icon="el-icon-edit" disabled
              >数据规则</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <addMenus ref="addMenuModules" />
    <showButton ref="showButtonModules" />
  </div>
</template>

<script>
import showButton from "./modules/showButton.vue";
import { listDataProductApi } from "@/api/system/product/product.js";
import addMenus from "./modules/addMenus.vue";
import { treeMenuShapeListV1Api } from "@/api/system/systemCommon/menu.js";
import { getEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  name: "menusList",
  components: {
    addMenus,
    showButton,
  },
  data() {
    return {
      radio: null,
      menuDataList: [],
      queryMenuFrom: {
        type:null
      },
      systemList: [],
      statusOption: [],
      questionnaireId: "",
      templateSelection: {},
      apiMethodList: [],
      apiTypeList: [],
      twoTypeList: [],
      commonBnCssTypeList: [],
      menusTypeList:[]
    };
  },
  created() {
    this.listByUserButton();
    this.menuListButton();
    this.getEnumButtonList();
  },
  methods: {
    getCurrentRow(row) {
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.id;
        this.templateSelection = row;
      }
    },
    menuListButton() {
      treeMenuShapeListV1Api(this.queryMenuFrom).then((res) => {
        this.menuDataList = res.result;
      });
    },
    listByUserButton() {
      this.queryMenuFrom.attribute = 1;
      listDataProductApi(this.queryMenuFrom).then((res) => {
        this.systemList = res.result;
      });
    },
    addMenuButton() {
      this.$refs.addMenuModules.show(null, 1,1);
    },
    editMenu(index, row) {
      this.$refs.addMenuModules.show(row, 2,1);
    },
    tableRowClassName(row) {
      if (row === 2) {
        return "success";
      }
    },
    showButtonListButton(row) {
      this.$refs.showButtonModules.show(row, 1);
    },
    getEnumButtonList() {
      // group
      getEnumList({ enumName: "ApiTypeEnum", group: true }).then((res) => {
        this.twoTypeList = res.result.commonBnType;
        this.commonBnCssTypeList = res.result.commonBnCssType;
        this.apiMethodList = res.result.commonApiMethod;
        this.apiTypeList = res.result.commonApiType;
      });
      this.selectDictItemSyn(this.systemSource, "menu_type").then(
        response => {
          this.menusTypeList = response.result;
        }
      );
     
    },
    mensTypeMenus(row, column) {
      return this.selectDictLabel(this.menusTypeList, row.type);
    },
  },
};
</script>

<style lang="less" scoped>
</style> 